@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

// font
$font-family: "Poppins", sans-serif;

[data-theme="light"] {
  // custom
  --primary: #1b469e;
  --accent: #49beff;
  --warning: #F4BE41; /* Nuevo color de alerta */
  --error: #ff5d5d;
  --success: #5D9240;
  --white: #ffffff;
  --background: #ffffff;
  --background-secundary: #F2F3F6;
  --card-background: #f9f9f9;
  --divider: #11111126;

  --card-secundary: #DAE5FC;

  --primary-700: #102a5f;
  --primary-600: #16387E;
  --primary-500: #1b469e;
  --primary-300: #6784BE;
  --primary-400: #4E6FB4;
  --primary-200: #8098c9;
  --primary-100: #9AADD4;
  --primary-50: #b3c1df;
  --primary-20: #e8ebf1;
  --primary-10: #EEF1F7;

  //gray
  --gray-100: #29293a;
  --gray-90: #3e3e4e;
  --gray-80: #545461;
  --gray-70: #696975;
  --gray-40: #A9A9B0;
  --gray-05: #ffffff;

  --font-family: "Poppins";

  // alert colors
  --warning-900: #8E5800;
  --warning-800: #A77200;
  --warning-700: #C18B0E;
  --warning-600: #DAA428;
  --warning-500: #F4BE41; /* Ya está asignado como --warning */
  --warning-400: #F5C554;
  --warning-300: #F6CB67;
  --warning-200: #F7D17A;
  --warning-100: #F8D88D;
  --warning-50: #F9DFA0;

  --success-900: #002C00;
  --success-800: #114600;
  --success-700: #2A5F0D;
  --success-600: #447927;
  --success-500: #5D9240; /* Ya está asignado como --success */
  --success-400: #6D9D53;
  --success-300: #7DA866;
  --success-200: #8EB379;
  --success-100: #9EBE8C;
  --success-50: #AEC99F;

  // error colors
  --error-900: #5C0000;
  --error-800: #760000;
  --error-700: #8F0000;
  --error-600: #A8150E;
  --error-500: #C22E27;
  --error-400: #C8433D;
  --error-300: #CE5852;
  --error-200: #D46D68;
  --error-100: #DA827D;
  --error-50: #E19693;


}

[data-theme="dark"] {
  --primary: #1b469e;
  --accent: #536e7a;
  --warning: #F5C554; /* Nuevo color de alerta */
  --error: #f44336;
  --success: #4caf50;
  --white: #fafafa;
  --background: #161616;
  --background-secundary: #161616;
  --card-background: #3e3e4e;
  --divider: #343333;

  --card-secundary: #2B2C41;

  --primary-700: #6784BE;
  --primary-500: #8098C9;
  --primary-600: #4E6FB4;
  --primary-200: #D0DDF9;
  --primary-400: #A4B4D5;
  --primary-300: #BDCAE5;
  --primary-50: #DBE3F6;
  --primary-20: #DEE5F4;
  --primary-10: #EEF1F7;

  //gray
  --gray-100: #eaeaeb;
  --gray-90: #d4d4d8;
  --gray-80: #bfbfc4;
  --gray-70: #a9a9b0;
  --gray-05: #282830;

  --font-family: "Poppins";

  // alert colors
  --warning-900: #8E5800;
  --warning-800: #A77200;
  --warning-700: #C18B0E;
  --warning-600: #DAA428;
  --warning-500: #F5C554; /* Ya está asignado como --warning */
  --warning-400: #F4BE41;
  --warning-300: #F6CB67;
  --warning-200: #F7D17A;
  --warning-100: #F8D88D;
  --warning-50: #F9DFA0;

  --success-900: #002C00;
  --success-800: #114600;
  --success-700: #2A5F0D;
  --success-600: #447927;
  --success-500: #6D9D53; /* Ya está asignado como --success */
  --success-400: #5D9240;
  --success-300: #7DA866;
  --success-200: #8EB379;
  --success-100: #9EEB8C;
  --success-50: #AEC99F;

  // error colors
  --error-900: #E19693;
  --error-800: #DA827D;
  --error-700: #D46D68;
  --error-600: #CE5852;
  --error-500: #C8433D;
  --error-400: #C22E27;
  --error-300: #A8150E;
  --error-200: #8F0000;
  --error-100: #760000;
  --error-50: #5C0000;
}

$white: #ffffff;

$link-color: #71a6ff;
